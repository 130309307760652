<template>
    <loader v-if="loading" :style="'box'"></loader>
    <div v-if="!loading">
    <div class="grid">
        <div class="col-12 xl:col-6">
            <div class="card height-100 flex flex-column">
                <div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
                    <!-- <img src="layout/images/widgets/feature-faq.svg" alt="feature-faq"> -->
                    <h2 class="m-0">InfluxDB</h2>
                </div>
                <div class="formgrid mt-3">
                    <div class="field col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Server</span>
                            <InputText v-model="setupInfluxdb.server" placeholder="Server-Adresse" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Port</span>
                            <InputNumber v-model="setupInfluxdb.port" placeholder="8086" :min=0 :max=65535 :useGrouping="false" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Token</span>
                            <InputText v-model="setupInfluxdb.token" placeholder="1234aa4dasd654sa6d" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Organisation</span>
                            <InputText v-model="setupInfluxdb.org" placeholder="Elektronation GmbH" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Bucket</span>
                            <InputText v-model="setupInfluxdb.bucket" placeholder="records" />
                        </div>
                    </div>
                    <Divider />
                </div>
            </div>
        </div>

        <div class="col-12 xl:col-6">
            <div class="card height-100 flex flex-column">
                <div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
                    <!-- <img src="layout/images/widgets/feature-faq.svg" alt="feature-faq"> -->
                    <h2 class="m-0">ENATSRV</h2>
                </div>
                <div class="formgrid mt-3">
                    <div class="field col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Session Timeout</span>
                            <InputNumber v-model="setupEnatsrv.sessionTimeout" placeholder="1 Tage" suffix=" Tage" :useGrouping="false" :min="1" :max="365" />
                        </div>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Refresh Timeout</span>
                            <InputNumber v-model="setupEnatsrv.refreshTimeout" placeholder="1200 Sekunden" suffix=" Sekunden" :useGrouping="false" :min="100" :max="9999" />
                        </div>
                    </div>
                    <Divider />
                </div>
            </div>
        </div>
    </div>
        
        <div class="flex justify-content-end">
            <div class="flex align-items-center justify-content-center m-2">
                <Button label="Speichern" @click="saveConfig" />
            </div>
        </div>
    </div>
</template>

<script>
import SocketioService from '@/services/socketioService';

export default {
    name: 'configSysNetwork',
    data() {
        return {
            products: null,
            loading: false,
            setupApp: {
                standbyTimeout: 999,
            },
            setupAddresses: {},
            setupInfluxdb: {},
            setupEnatsrv: {
                sessionTimeout: 86400,
                refreshTimeout: 1200,
            }
        };
    },
    created() {},
    mounted() {
        this.init();
    },
    beforeUnmount() {
        this.products = null;
        this.loading = false;
        this.setupApp = {
            standbyTimeout: 999,
        };
        this.setupNetworkId = null;
        this.setupAddresses = {};
        this.setupInfluxdb = {};
        this.setupEnatsrv = {};
    },
    methods: {
        async init() {
            this.loading = true;
            await this.getParameter();
            this.loading = false;
        },
        async getParameter() {
            await this.getSystemNetwork();
        },
        async getSystemNetwork() {
            await SocketioService.getSystemNetwork((err, response) => {
                if (!err && response !== null) {
                    this.setupNetworkId = response._id;
                    this.setupAddresses = response.addresses;
                    this.setupInfluxdb = response.influxdb;
                    if (response.enatsrv) this.setupEnatsrv = response.enatsrv;
                }
            });
        },
        toastMongoResponse(title, response) {
            if (response.acknowledged && response.matchedCount > 0 && response.upsertedCount === 0 && response.modifiedCount === 0) {
                if (title !== null) {
                    this.$toast.add({
                        severity: 'info',
                        summary: 'Keine Änderungen',
                        detail: title + ' modifiziert: ' + response.modifiedCount + ' , keine Änderung notwendig ',
                        life: 3000,
                    });
                }
                return 0;
                // document updated
            } else if (response.acknowledged && response.matchedCount > 0 && response.upsertedCount === 0 && response.modifiedCount > 0) {
                if (title !== null) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Speichern erfolgreich',
                        detail: title + ' modifiziert: ' + response.modifiedCount + ' , alle Änderungen wurden gespeichert ',
                        life: 3000,
                    });
                }
                return 1;
                // document deleted
            } else if (response.acknowledged && response.deletedCount > 0) {
                if (title !== null) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Löschen erfolgreich',
                        detail: title +  ' ' + response.deletedCount + ' Einträge erfolgreich gelöscht',
                        life: 3000,
                    });
                }
                return 2;
                // document created
            } else if (response.acknowledged && response.insertedId !== null) {
                if (title !== null) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Konfiguration erstellt',
                        detail: title + ' Konfiguration [' + response.insertedId.toString() + '] erfolgreich erstellt',
                        life: 3000,
                    });
                }
                return 3;
            } else {
                if (title !== null) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Speichern fehlgeschlagen',
                        detail: title + ' modifiziert: ' + response.modifiedCount + ' , Erstellt: ' + response.upsertedCount + ' [' + response.upsertedId + ']',
                        life: 3000,
                    });
                }
                return -1;
            }
        },
        async saveNetwork() {
            const returnObj = {
                _id: this.setupNetworkId,
                addresses: { ...this.setupAddresses },
                influxdb: { ...this.setupInfluxdb },
                enatsrv: { ...this.setupEnatsrv }
            }
            await SocketioService.setSystemNetwork(returnObj, (err, response) => {
                if (!err && response !== null) {
                    this.toastMongoResponse("Netzwerk-Konfiguration", response);
                }
            });
        },
        async saveConfig() {
            this.loading = true;
            await this.saveNetwork();
            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.p-inputgroup-addon {
    width: 200px;
}
</style>